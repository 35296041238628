<template>
  <a-spin :spinning="isLoading">
    <div v-if="!showPageError" class="detail-hotline">
      <div class="hotline-header">
        <a-button type="primary" @click="onBack"> <a-icon type="left" />Quay lại</a-button>
        <div class="header-text">
          <div class="title">Danh sách hotline - {{ data.typeName }}</div>
          <a-button type="primary" @click="fetchMore">Lấy thêm số</a-button>
        </div>
      </div>
      <a-table
        :columns="columns"
        :data-source="detail"
        :pagination="{ pageSize: detail.length }"
        :row-key="record => record.id"
        bordered
      >
        <div slot="index" slot-scope="text, record, index" @click="onHandleClickRow(record)">
          {{ index + 1 }}
        </div>
        <div slot="name" slot-scope="text, record" @click="onHandleClickRow(record)">
          {{ text }}
        </div>
        <div slot="phone" slot-scope="text, record" @click="onHandleClickRow(record)">
          {{ record.customer.phone.replace(/^.{6}/g, '******') }}
        </div>
        <div slot="statusName" slot-scope="text, record" @click="onHandleClickRow(record)">
          {{ text }}
        </div>
        <div slot="action" slot-scope="text, record" @click="onHandleClickQrCode(record)">
          Quét mã
        </div>
      </a-table>
      <a-modal
        :title="activeCustomer.name ? 'QR CODE - ' + activeCustomer.name : ''"
        :visible="visible"
        @cancel="onCancelModal"
        :destroyOnClose="true"
        :footer="null"
      >
        <div class="qr-code">
          <qrcode-vue :value="activeCustomer.id" :size="200" level="Q" />
        </div>
      </a-modal>
    </div>
  </a-spin>
</template>

<script>
import moment from 'moment'
import QrcodeVue from 'qrcode.vue'
import { RepositoryFactory } from '@/api/RepositoryFactory'

const HotlineRepository = RepositoryFactory.get('hotlineRepository')
const columns = [
  {
    title: 'STT',
    width: '10%',
    key: 'index',
    scopedSlots: { customRender: 'index' }
  },
  {
    title: 'Tên khách',
    dataIndex: 'customer.name',
    width: '25%',
    scopedSlots: { customRender: 'name' }
  },
  {
    title: 'Số điện thoại',
    dataIndex: 'phone',
    width: '20%',
    scopedSlots: { customRender: 'phone' }
  },
  {
    title: 'Trạng thái',
    dataIndex: 'statusName',
    width: '35%',
    scopedSlots: { customRender: 'statusName' }
  },
  {
    title: 'QRCode',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    width: '20%'
  }
]
export default {
  name: 'DetailHotline',
  components: { QrcodeVue },
  data() {
    return {
      detail: [],
      isLoading: false,
      showPageError: false,
      pageErrorCode: 0,
      data: this.$route.params,
      columns,
      visible: false,
      activeCustomer: {}
    }
  },
  created() {
    this.fetchDetail()
  },
  methods: {
    onHandleClickQrCode(record) {
      this.visible = true
      this.activeCustomer = {
        id: record.id.toString(),
        name: record.customer.name
      }
    },
    onHandleClickRow(record) {
      window.open('/khach-hang/thong-tin-chung/' + record.customer.phone, '_blank')
    },
    handleApiFetchError(error) {
      if (error && error.response && error.response.status == 401) {
        this.showPageError = true
        this.pageErrorCode = 401
      }
    },
    async fetchDetail() {
      const today = moment().format('DD-MM-YYYY')
      this.isLoading = true
      try {
        const { type } = this.$route.params
        const params = {
          type,
          date: today
        }
        const response = await HotlineRepository.getDetailHotline(params)
        this.detail = response
      } catch (error) {
        this.isLoading = false
        this.handleApiFetchError(error)
      } finally {
        this.isLoading = false
      }
    },
    async fetchMore() {
      const today = moment().format('DD-MM-YYYY')
      this.isLoading = true
      try {
        const { type } = this.$route.params
        const params = {
          type,
          date: today
        }
        await HotlineRepository.getMoreHotline(params)
        this.fetchDetail()
      } catch (error) {
        this.isLoading = false
      } finally {
        this.isLoading = false
      }
    },
    onBack() {
      this.$router.go(-1)
    },
    onCancelModal() {
      this.visible = false
      this.activeCustomer = {}
    }
  }
}
</script>

<style lang="scss">
.detail-hotline {
  .hotline-header {
    background-color: #fff;
    padding: 12px;
    margin-bottom: 16px;
    .title {
      font-weight: bold;
      font-size: 24;
      text-transform: uppercase;
      margin-right: 16px;
    }
    .action-back {
      display: flex;
      align-items: center;
      &-text {
        margin-left: 10px;
      }
    }
    .header-text {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;
    }
  }
  .ant-pagination {
    display: none;
  }
  .ant-table-row {
    cursor: pointer;
    background-color: #fff;
  }
}
.qr-code {
  text-align: center;
}
</style>
